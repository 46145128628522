import * as React from "react";
import { graphql } from "gatsby";
import { MainLayout } from "../layout/main/main.layout";
import { HeroSection } from "../section/hero/hero.section";
import { AboutOfSection } from "../section/about-of/about-of.section";
import { OurServicesSection } from "../section/our-services/our-services.section";
import { PricePackagesSection } from "../section/price-packages/price-packages.section";
import { WorkProcessSection } from "../section/work-process/work-process.section";
import { OurProjectsSection } from "../section/our-projects/our-projects.section";
import { OurBlogsSection } from "../section/our-blogs/our-blogs.section";
import { SeoComponent } from "../components/seo/seo.component";
import "../css/style.scss";
import "../css/blog-style.scss";

export const Head = ({data}: {data: any}) => {
  const { siteMetadata } = data.site;
  const pageData = {
    title: "Onixdev | Desarrollo de software, apps y webs personalizadas",
    siteUrl: siteMetadata.siteUrl,
    url: siteMetadata.url,
    author: siteMetadata.author,
    lang: siteMetadata.lang,
    charSet: siteMetadata.charSet,
    image: siteMetadata.image,
    description: siteMetadata.description,
    twitterUsername: siteMetadata.twitterUsername,
    menuLinks: siteMetadata.menuLinks
  }

  return (
    <>
      <meta name="resource-type" content="Homepage"></meta>
      <SeoComponent {...pageData}/>
    </>
  );
};


const IndexPage = ({ data }: { data: any; }) => {
  return (
    <MainLayout siteMetaData={data.site.siteMetadata} path="">
      <section id="inicio" className="hero-main-section">
        <HeroSection />
      </section>
      <section id="acerca-de" className="main-section">
        <AboutOfSection />
      </section>
      <section id="servicios" className="main-section">
        <OurServicesSection />
      </section>

      <div className="line-section"></div>

      <section id="precios" className="main-section">
        <PricePackagesSection />
      </section>

      <section id="procesos" className="main-section">
        <WorkProcessSection />
      </section>

      <section id="proyectos" className="main-section">
        <OurProjectsSection />
      </section>

      <div className="line-section"></div>

      <section id="our-blogs" className="main-section">
        <OurBlogsSection data={data.allMdx} path="home"/>
      </section>

    </MainLayout>
  );
};

export default IndexPage;

export const query = graphql`
  query SiteMetaData {
    site {
      siteMetadata {
        author
        description
        image
        menuLinks {
          link
          name
        }
        siteUrl
        title
        url
        lang
        charSet
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 4) {
      edges{
        node {
          id
          frontmatter {
            date
            description
            title,
            thumbnail {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
