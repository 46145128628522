import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./project-card.component.scss";

export const ProjectCardComponent = ({ projectCardData }: { projectCardData: any }) => {
  return (
    <div className="project-card">
      
      <GatsbyImage
        className="project-card-image"
        image={projectCardData.image.childImageSharp.gatsbyImageData}
        alt={"Imagen sobre el " + projectCardData.title + " desarollado por Onixdev" }
      />

      <div className="project-card-content-container"
        onClick={
          () => {
            if(projectCardData.url !== "") { window.open(projectCardData.url)};
          }
        }
      >
        <div className="project-card-content">
          <label className="project-card-type">{projectCardData.tipo}</label>
          <div className="project-card-title">
            <h3 className="title"><b>{projectCardData.title}</b></h3>
          </div>
          <div className="project-card-description">
            <p>{projectCardData.description}</p>
          </div>
          {
            projectCardData.url !== "" ? 
              <div className="project-card-buttons">
                <button className="button" onClick={() => {
                  window.open(projectCardData.url);
                }}>Visitar Sitio Web</button>
              </div>
              :
              <></>
          }
        </div>

      </div>
    </div>
  );
};
