import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { WorkProcessComponent } from "../../components/work-process/work-process.component";
import "./work-process.section.scss";

export const WorkProcessSection = () => {
  return (
    <div className="work-process-section">
      <StaticImage
        className="work-process-background"
        src="../../assets/images/about-background.png"
        alt="Imagen de fondo de los procesos de trabajo de Onixdev"
        placeholder="tracedSVG"
      />
      <WorkProcessComponent />
    </div>
  );
};
