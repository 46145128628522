import React from "react";
import "./about.of.component.scss";
import CheckCircleSVG from "../../assets/check-circle-fill.svg";
import { StaticImage } from "gatsby-plugin-image";

export const AboutOfComponent = () => {
  const features: {
    id: string;
    title: string;
    icon: string;
    description: string;
  }[] = [
      {
        id: "1",
        title: "Software a medida.",
        icon: "",
        description: "1",
      },
      {
        id: "2",
        title: "Diseño y planificación.",
        icon: "",
        description: "2",
      },
      {
        id: "3",
        title: "Desarrollo ágil.",
        icon: "",
        description: "3",
      },
      {
        id: "4",
        title: "Experiencia de usuario.",
        icon: "",
        description: "4",
      },
      {
        id: "5",
        title: "Evaluación y pruebas.",
        icon: "",
        description: "5",
      },
      {
        id: "6",
        title: "Despliegue continuo.",
        icon: "",
        description: "6",
      },
      {
        id: "7",
        title: "Y más.",
        icon: "",
        description: "6",
      },
  ];

  return (
    <div className="about-of">
      <div className="about-of-container">
        <div className="image-container">
          <StaticImage
            className="about-of-image"
            src="../../assets/images/about-picture.png"
            alt="Imagen acerca de Onixdev"
            placeholder="tracedSVG"
          />
        </div>

        <div className="about-of-content-container">
          <div className="title-container">
            <StaticImage
              className="title-background"
              src="../../assets/images/title-background-white.png"
              alt="Imagen de fonto Acerca de Onixdev"
              placeholder="tracedSVG"
            />
            <h2 className="about-of-content-title">Acerca De Nosotros</h2>  
          </div>

          <h3 className="about-of-content-subtitle">Somos una Startup creativa e innovadora</h3>

          <p className="about-of-content">
            Startup establecida en la ciudad de Quito - Ecuador,
            fundada con el objetivo de brindar soluciones
            digitales creativas e innovadoras que se ajusten
            a las necesidades de nuestros clientes y sus
            proyectos.
          </p>

          <div className="feature-container">
            <ul>
              {features.map((feature) => {
                return (
                  <li key={feature.id} className="item-feature">
                    <CheckCircleSVG className="icon" />
                    <p key={"h-" + feature.id} className="item" >{feature.title}</p>
                  </li>
                );
              })}
            </ul>
          </div>
          
          <div className="about-of-buttons">
            <button className="about-of-button-cotizar" onClick={() => {
                window.open("/pdf/Folleto_de_Servicios_ONIXDEV_2022.pdf")
              }}>Conocer más</button>
          </div>
        </div>
      </div>
    </div>
  );
};
