import { graphql, navigate, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { ProjectCardComponent } from "../../components/project-card/project-card.component";
import "./our-projects.section.scss";

export const OurProjectsSection = () => {

  const allProjectsData = useStaticQuery(
    graphql`
      query getAllProjectsData {
        allProjectsJson {
          edges {
            node {
              id
              title
              tipo
              description
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    formats: WEBP
                    pngOptions: {compressionSpeed: 53}
                    transformOptions: {fit: CONTAIN, cropFocus: CENTER}
                  )
                }
              }
              url
            }
          }
        }
      }    
    `
  );

  const getAllprojectCads = (getAllProjectsData: any) => {
    const projectArray: any[] = [];
    getAllProjectsData.allProjectsJson.edges.forEach(
      (projectData: any) => {
        projectArray.push(
          <ProjectCardComponent key={projectData.node.id} projectCardData={projectData.node} />
        );
      }
    );
    return projectArray;
  }

  return (
    <>
      <div className="projects-section">
        
        <div className="projects-header">
          <div className="title-container">
            <StaticImage
              className="title-background"
              src="../../assets/images/title-background-black.png"
              alt="Imagen de fondo del título para los Proyectos de Onixdev"
              placeholder="tracedSVG"
            />
            <h2 className="project-title">Proyectos</h2>
          </div>

          <h3 className="project-subtitle">Soluciones digitales únicas y personalizados para cada cliente</h3>
          <p className="project-description">
            {/* Nuestro trabajo refleja nuestra expleriencia. Cada */}
            Proyectos personalizados, diseñados y adaptados a las necesidades de nuestros clientes, ofreciendo productos y servicios de caldidad.
          </p>
        </div>

        <div className="projects-cards">
          {
            getAllprojectCads(allProjectsData)
          }
        </div>

        <div className="projects-buttons">
          <button className="button" onClick={() => { window.open("/pdf/Folleto_de_Servicios_ONIXDEV_2022.pdf") }}>Conocer Más</button>
        </div>
      </div>
    </>
  );
};
