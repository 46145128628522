import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./service-card.component.scss";
import CheckCircleSVG from "../../assets/check-circle-fill.svg";

export const ServiceCardComponent = ({ cardData, index }: { cardData: any, index: number }) => {

  const generateDescription = (descriptionData: any, id: string) => {
    const listDescription: any[] = [];
    descriptionData.forEach(
      (description: any, index: any) => {
        listDescription.push(
          <li key={id + "-" + index} className="service-items">
            <div className="icon" >
              <CheckCircleSVG />
            </div>
            <p key={id + "-intem-" + index} className="item" >{description}</p>
          </li>
        );
      }
    );
    return listDescription;
  }

  return (
    <div className={"service-card service-" + index}>
      <div className="service-card-container">

        <GatsbyImage
          className="service-card-image"
          image={cardData.image.childImageSharp.gatsbyImageData}
          alt={"Imagen sobre el servicio de " + cardData.title + " que ofrece Onixdev"}
        />

        <div className="service-card-content">
          <div className="service-card-title">
            <h2 className="title"><b>{cardData.title}</b></h2>
          </div>

          <div className="services-items-container">
            {generateDescription(cardData.description, cardData.id)}

          </div>
        </div>
      </div>
    </div>
  );
};
