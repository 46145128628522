import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { AboutOfComponent } from "../../components/about-of/about.of.component";
import "./about-of.section.scss";

export const AboutOfSection = () => {
  return (
      <div className="about-of-section">
        <StaticImage
            className="about-of-background"
            src="../../assets/images/about-background.png"
            alt="Imagen de fondo de la sección acerca de Onixdev"
            placeholder="tracedSVG"
        />
        <AboutOfComponent />
      </div>
  );
};
