import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ServiceCardComponent } from "../../components/service-card/service-card.component";
import { OnixdevInfoData } from "../../config/site.config";
import "./our-services.section.scss";

export const OurServicesSection = () => {

  const allServicesData = useStaticQuery(
    graphql`
      query getAllServicesData {
        allServicesJson {
          edges {
            node {
              id
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    formats: WEBP
                    pngOptions: {compressionSpeed: 53}
                    transformOptions: {fit: CONTAIN, cropFocus: CENTER}
                  )
                }
              }
              whatsappMessage
            }
          }
        }
      }    
    `
  );

  const getAllServicesCads = (allServiceData: any) => {
    const servicesArray: any[] = [];
    allServiceData.allServicesJson.edges.forEach(
      (serviceData: any, index: number) => {
        servicesArray.push(
          <ServiceCardComponent key={serviceData.node.id} index={index} cardData={serviceData.node} />
        );
      }
    );
    return servicesArray;
  }

  return (
    <>
      <div className="services-section">
        
        <div className="services-header">
          <div className="title-container">
            <StaticImage
              className="title-background"
              src="../../assets/images/title-background-black.png"
              alt="Imagen de fondo del titulo para los Servicios de Onixdev"
              placeholder="tracedSVG"
            />
            <h2 className="service-title">Servicios</h2>  
          </div>

          <h3 className="service-subtitle">Soluciones digitales a tu medida</h3>
          <p className="service-description">
          Ofrecemos una gran variedad de servicios que se ajustan a tus necesidades
          </p>
        </div>
      
        <div className="services-cards">
          {
            getAllServicesCads(allServicesData)
          }
        </div>

        <div className="services-buttons">
          <button className="button" onClick={() => { window.open( OnixdevInfoData.whatsappLink + "Saludos, me ayudarían con más más información sobre sus servicios.",'_blank') }}>Cotizar</button>
          <button className="button" onClick={() => { window.open("/pdf/Folleto_de_Servicios_ONIXDEV_2022.pdf") }}>Conocer Más</button>
        </div>
      </div>
    </>
  );
};
