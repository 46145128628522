import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { PricePackageCardComponent } from "../../components/price-package-card/price-package-card.component";
import "./price-packages.section.scss";

export const PricePackagesSection = () => {

  const allPricePackagesData = useStaticQuery(
    graphql`
      query getPricePackagesData {
        allPricePackagesJson {
          edges {
            node {
              id
              title
              price
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    formats: WEBP
                    pngOptions: {compressionSpeed: 53}
                    transformOptions: {fit: CONTAIN, cropFocus: CENTER}
                  )
                }
              }
              name
              description
              features
              whatsappMessage
            }
          }
        }
      }    
    `
  );

  const getAllServicesCads = (allPricePackagesData: any) => {
    const servicesArray: any[] = [];
    allPricePackagesData.allPricePackagesJson.edges.forEach(
      (serviceData: any) => {
        servicesArray.push(
          <PricePackageCardComponent key={serviceData.node.id} cardData={serviceData.node} />
        );
      }
    );
    return servicesArray;
  }

  return (
    <>
      <div className="price-packages-section">
        
        <div className="price-packages-header">
          <h2 className="price-package-subtitle">Paquete de sitios web</h2>
          <p className="price-package-description">
            Los mejores precios en sitios webs estáticos, al alcance de tu bolsillo
          </p>
        </div>
      
        <div className="price-packages-cards">
          {
            getAllServicesCads(allPricePackagesData)
          }
        </div>

        <div className="price-packages-buttons">
          <button className="button" onClick={() => { window.open("/pdf/Folleto_de_Servicios_ONIXDEV_2022.pdf") }}>Conocer Más</button>
        </div>
      </div>
    </>
  );
};
