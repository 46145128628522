import React from "react";
import { OnixdevInfoData } from "../../config/site.config";
import "./hero.component.scss";

export const HeroComponent = () => {
  return (
    <div className="hero-container">

      <div className="hero-description">

        <p>Soluciones digitales creativas e innovadoras</p>
        <h2>DESARROLLO ÁGIL DE SOFTWARE</h2>

        <div className="hero-buttons">
          <button className="button-cotizar-hero" onClick={() => {
            window.open( OnixdevInfoData.whatsappLink + "Saludos, estuve navegando en su sitio web www.onixdev.com y quisiera más información.",'_blank')
          }}>COTIZAR</button>
        </div>

      </div>

    </div>
  );
};
