import React from "react";
import CheckCircleSVG from "../../assets/check-circle-fill.svg";
import "./leaf-card.component.scss";

export const LeafCardComponent = ({ leafCardData }: { leafCardData: any }) => {

  return (
    <div className="leaf-card-container">
      <div className="leaf-card">
        <div className="leaf-card-icon" >
          <div className="icon" >
            <CheckCircleSVG />
          </div>
        </div>

        <div className="leaf-card-content-container">
          <div className="leaf-card-content">
            <div className="leaf-card-title">
              <h3 className="title"><b>{leafCardData.title}</b></h3>
            </div>

            <div className="leaf-card-description">
              <p>{leafCardData.description}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
