import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { HeroComponent } from "../../components/hero/hero.component";
import "./hero.section.scss";

export const HeroSection = () => {
  return (
    <>
      <div className="hero-background-container">
        <StaticImage
          className="hero-background-1"
          src="../../assets/images/hero-background.png"
          alt="Imagen de fondo de inicio de Onixdev"
          placeholder="tracedSVG"
        />
        
      </div>
      <div className="hero-section">
        <HeroComponent />

        <div className="mouse_scroll">
            <div className="mouse">
              <div className="wheel"></div>
            </div>
            <div>
              <span className="m_scroll_arrows uno"></span>
              <span className="m_scroll_arrows dos"></span>
              <span className="m_scroll_arrows tres"></span>
            </div>
          </div>
      </div>
    </>
  );
};
