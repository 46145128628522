import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import CheckCircleSVG from "../../assets/check-circle-fill.svg";
import "./price-package-card.component.scss";
import { OnixdevInfoData } from "../../config/site.config";

export const PricePackageCardComponent = ({ cardData }: { cardData: any }) => {
  const generateDescription = (featuresData: any, id: string) => {
    const listDescription: any[] = [];
    featuresData.forEach(
      (feature: any, index: any) => {
        listDescription.push(
          <li key={id + "-" + index} className="price-package-items">
            <div className="icon" >
              <CheckCircleSVG size={26} />
            </div>
            <p key={id + "-intem-" + index} className="item" >{feature}</p>
          </li>
        );
      }
    );
    return listDescription;
  }

  return (
    <div className={"price-package-card " + cardData.name}>
      <div className="price-package-card-container">

        <div className="price-package-card-title">
          
          <div className="label-container">
              
            <p className="label">
              Promoción
            </p>
          </div>

          <p className="title">
            {cardData.title}
          </p>
          
          {/* <h3 className="subtitle">
            {cardData.price}
          </h3> */}
          
          <GatsbyImage
            className="price-package-card-image"
            image={cardData.image.childImageSharp.gatsbyImageData}
            alt={"Imagen sobre " + cardData.title }
          />
          
          <p className="description">{cardData.description}</p>

        </div>

        <div className="price-package-card-content">
          
          <p className="label-price"><b>- INCLUYE -</b></p>
          <div className="price-packages-items-container">
            {generateDescription(cardData.features, cardData.id)}
          </div>
        </div>

        <div className="price-package-card-buttons">
          <button className="button" onClick={() => {
            window.open( OnixdevInfoData.whatsappLink + cardData.whatsappMessage,'_blank')
          }}>Cotizar</button>
        </div>

      </div>
    </div>
  );
};
