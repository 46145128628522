import React from "react";
import "./work-process.component.scss";
import { LeafCardComponent } from "../leaf-card/leaf-card.component";

export const WorkProcessComponent = () => {

  return (
    <div className="work-process-container">

      <div className="work-process-header">
        <h2 className="work-process-subtitle">Nuestro Proceso de Trabajo</h2>
        <p className="work-process-description">
          Nuestro equipo de desarrollo sigue un proceso de 5 fases para completar con éxito un proyecto, misma que puede ser ajustada en base a las necesidades de cada proyecto.
        </p>
      </div>

      <div className="work-process">
        <div className="process process-1">
          <LeafCardComponent leafCardData={{ title: "FASE 1:", description: "Necesidades del Cliente" }} />
        </div>
        
        <div className="curve curve-1"></div>

        <div className="process process-2">
          <LeafCardComponent leafCardData={{ title: "FASE 2:", description: "Planificación y Diseño" }} />
        </div>
        
        <div className="curve curve-2"></div>

        <div className="process process-3">
          <LeafCardComponent leafCardData={{ title: "FASE 3:", description: "Desarrollo Ágil" }} />
        </div>
        
        <div className="process process-4">
          <LeafCardComponent leafCardData={{ title: "FASE 4:", description: "Evaluación y Pruebas" }} />
        </div>

        <div className="curve curve-3"></div>
        
        <div className="process process-5">
          <LeafCardComponent leafCardData={{ title: "FASE 5:", description: "Puesta en Producción" }} />
        </div>

        <div className="curve curve-4"></div>
      </div>

      <div className="work-process-buttons">
        <button className="button"
          onClick={
            () => {
              window.open("/pdf/Folleto_de_Servicios_ONIXDEV_2022.pdf")
            }
          }
        >
          Conocer más
        </button>
      </div>
    </div>
  );
};
